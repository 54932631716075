.proterate-layout{
    display: flex;
    background-color: #F8F9FA;

    & .proterate-layout-drawer{
        @media screen and (max-width: 991px){
            display: none;
        }

        & .MuiDrawer-paper{
            background-color: #F8F9FA;
            border-right: none;
        }
    }

    & .proterate-layout-bottom-navigation{
        display: none;
        @media screen and (max-width: 991px){
            display: block;
        }
    }

    & .proterate-layout-drawer-containter-list-item{   
        padding: 10px 15px 10px 15px; 
        & .MuiListItemButton-root:hover{
            padding: 10px 15px 10px 16px;
            background-color: #fff;
            border-radius: 15px;
            border: 1px rgba(0,0,0,0.5) solid;
        }

        & .MuiListItemButton-root.active{
            padding: 10px 15px 10px 16px;
            background-color: #fff;
            border-radius: 15px;
            border: 1px rgba(0,0,0,0.5) solid;
        }
    }

    & .proterate-layout-drawer-containter-list-text{
        & span{
            font-family: 'Inter', sans-serif;
            font-weight: bold;
        }
    }

    & .proterate-layout-drawer-containter-list-icon{
        min-width: 40px;
    }

    & .proterate-layout-drawer-divider{
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }

    & .proterate-layout-drawer-containter-toolbar{
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
        height: 64px;
    }

    & .proterate-layout-drawer-containter-appbar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
    }

    & .proterate-layout-outlet{
        background-color: #F8F9FA;
        height: 100vh;
        flex-grow: 1;
        padding: 3px;
        overflow: auto;
    }

    & .proterate-layout-bottom-navigation{
        & .MuiBottomNavigation-root{
            & .active{
                background-color: #F8F9FA;
            }
        }

    }

}