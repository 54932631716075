.survey-list{
    & .MuiPaper-root{
        border-radius: 15px;
    }

    & .survey-list-card{
        cursor: pointer;
        border-radius: 15px;
        background-color: #F8F9FA;
        & .MuiCardActionArea-root{
            height: 170px;
        }
        & .MuiCardActionArea-root:hover{           
            background-color: #DEE2E6;           
        }
    }

    & .survey-list-card-add-new{
        & .MuiButtonBase-root{
            height: 170px;
            background-color: #F8F9FA;
        }
    }
}