.nps-survey-container{   
    position: relative;

    .nps-survey-card{
        position: fixed;        
        bottom: 0;
        right: 0;
        max-width: 420px;
        width: 100%;
    }
}


.survey-variant-1-desktop-container{
    @media screen and (max-width: 991px) {
        display: none;
    }
}


.survey-variant-1-mobile-container{   
    display: none;
    @media screen and (max-width: 991px) {
        display: block;
    }

    .nps-survey-card{
        position: fixed;        
        bottom: 0;      
        width: 100%;    
    }
}
